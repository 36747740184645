@font-face {
    font-family: "fa-light-300";
    src: url("./fonts/fa-light-300.ttf");
}

@font-face {
    font-family: "fa-regular-400";
    src: url("./fonts/fa-regular-400.ttf");
}

@font-face {
    font-family: "fa-brands-400";
    src: url("./fonts/fa-brands-400.ttf");
}

@font-face {
    font-family: "fa-duotone-900";
    src: url("./fonts/fa-duotone-900.ttf");
}

@font-face {
    font-family: "fa-solid-900";
    src: url("./fonts/fa-solid-900.ttf");
}

:root {
    --white: #fff;
    --black: #000;
    --dark-blue-900: #030725;
    /* Sidebar */
    --dark-blue-800: #181c37;
    /* Main-BG */
    --dark-blue-700: #20253d;
    /* Searchbar-BG */
    --dark-blue-500: #2d325a;
    /* border and Box-BG */
    --light-gray: #a0a6d1;
    /* Text Color */
    --theme-blue: #22c0ff;
    --theme-pink: #e80074;
}

.text-blue {
    color: var(--theme-blue);
}

.text-pink {
    color: var(--theme-pink);
}

.btn,
.btn.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-skyblue {
    background: var(--theme-blue);
    color: var(--white);
}

.btn-skyblue:hover {
    color: var(--white);
    box-shadow: inset 0 0 5px 100px rgba(0, 0, 0, 0.05);
}

.btn-gray-500 {
    background: var(--dark-blue-500);
    color: var(--light-gray);
}

.btn img {
    margin-right: 1rem;
}

body {
    font-family: "Montserrat", sans-serif;
    background: var(--dark-blue-800);
}

.accountation_page {
    min-height: 100vh;
    background: var(--black);
    color: var(--white);
}

.accountation_page h2 {
    font-weight: 700;
    text-transform: uppercase;
}

.login-btn .btn {
    color: #707070;
    font-size: 1.3rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
}

.login-btn .btn:hover {
    color: var(--white);
}

.floating_label_input .field {
    display: flex;
    flex-flow: column-reverse;
    margin-bottom: 1em;
    background: var(--white);
    /* padding: 0.5rem 1rem; */
    overflow: hidden;
}

.floating_label_input label,
.floating_label_input input {
    transition: all 0.2s;
    touch-action: manipulation;
    font-size: 0.8rem;
    text-align: left;
    color: var(--black);
}

.floating_label_input input {
    border: 0;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    cursor: text;
    background: transparent;
    /* padding: 0.5rem 0; */
    padding: 2rem 1rem;
    position: relative;
    z-index: 99;
    margin-bottom: -1px;
}

.floating_label_input input:focus {
    outline: 0;
}

.floating_label_input label {
    text-transform: uppercase;
    letter-spacing: 0.05em;
    position: relative;
    margin-bottom: -1.3rem;
    padding: 0 1rem;
    transform: translate(0, 0.5rem);
}

.floating_label_input input:focus+label::after {
    position: absolute;
    content: "";
    width: 5px;
    height: 83px;
    background: #ff5c87;
    left: 0px;
    top: -8px;
    z-index: 999;
}

.floating_label_input input:placeholder-shown+label {
    cursor: text;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2rem);
    margin-bottom: -1.3rem;
    padding: 0 1rem;
}

::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
}

.floating_label_input input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

.floating_label_input input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
}

.floating_label_input input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
}

.floating_label_input input:focus::-webkit-input-placeholder {
    opacity: 1;
}

.floating_label_input input:not(:placeholder-shown)+label,
.floating_label_input input:focus+label {
    transform: translate(0, 0.5rem);
    cursor: pointer;
    color: #707070;
}


/* Sidebar Starts */

.pro-sidebar {
    position: fixed;
    min-width: 240px;
    width: 240px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
    display: inline-block;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper,
.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background: transparent;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
    align-items: normal;
    justify-content: normal;
}

.pro-sidebar>.pro-sidebar-inner {
    background: #030725;
}

.pro-sidebar .pro-menu a {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.pro-sidebar .pro-menu a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5px;
    height: 100%;
    width: calc(100% - 5px);
    background: var(--dark-blue-800);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    opacity: 0;
    z-index: -1;
}

.pro-sidebar .pro-menu a:hover::before,
.pro-sidebar .pro-menu a.active:before {
    opacity: 1;
}

.pro-sidebar .pro-menu .pro-menu-item .pro-inner-item:hover>.pro-icon-wrapper .pro-icon {
    border-radius: 50%;
    overflow: hidden;
}


/* Sidebar Ends */


/* Dashboard Header */

.dashboard-header {
    padding-left: 250px;
    background: var(--dark-blue-800);
    border-bottom: 2px solid var(--dark-blue-500);
}

.closemenu {
    font-size: 28px;
    color: var(--light-gray);
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}

.pro-sidebar.collapsed .closemenu {
    right: 1.8rem;
}

.profile-avatar {
    text-decoration: none !important;
}

.profile-avatar p {
    font-size: 14px;
}

.dashboard-box {
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 30px;
    text-decoration: none !important;
    color: var(--light-gray);
    border: 1px solid var(--dark-blue-500);
    transition: 0.2s;
}

.dashboard-box:hover {
    color: var(--white);
    transform: scale(1.01);
}

.dashboard-box img {
    width: 90px;
}

.icon-small img {
    width: 65px;
}

.gradient-blue {
    background: radial-gradient(ellipse at 75% 25%, #b0bbfc 0%, #4866ff 100%);
    color: var(--white);
}

.gradient-violet {
    background: radial-gradient(ellipse at 75% 25%, #caabf9 0%, #813afb 100%);
    color: var(--white);
}

.gradient-pink {
    background: radial-gradient(ellipse at 75% 25%, #ecb8ff 0%, #b573e6 100%);
    color: var(--white);
}

.bg-dark-500 {
    background: var(--dark-blue-500);
}

.page-title {
    color: var(--white);
    margin-bottom: 0;
}

.section-title {
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--white);
}

.section-title.dark {
    color: var(--dark-blue-800);
}


/* Dashboard Header Ends */


/* Add Wine Screen */

.vint-vine-form .form-group {
    margin-bottom: 2rem;
}

.vint-vine-form .form-control {
    background: transparent !important;
    color: var(--white);
    border-radius: 0 !important;
    border: 0;
    border-bottom: 1px solid var(--light-gray);
}

.vint-vine-form .form-control:focus {
    color: var(--white);
    border-color: var(--theme-blue);
    outline: 0;
    box-shadow: none;
}

.vint-vine-form .btn-link {
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    color: var(--white);
    text-decoration: none;
}

.vint-vine-form .btn-link:hover {
    background: var(--light-gray);
}


/* Add Wine Screen Ends */


/* Show Wines Screen */

.show-wines .table-dark {
    color: var(--light-gray);
    background-color: transparent;
    font-size: 14px;
}

.search-bar {
    background: var(--dark-blue-700);
    border-radius: 5px;
    color: var(--light-gray);
    padding: 0.3rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-bar input {
    width: 90%;
    background: transparent !important;
    border: none;
    box-shadow: none !important;
    color: var(--white) !important;
}

.export-csv-btn {
    background: var(--dark-blue-500);
    color: var(--light-gray);
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.export-csv-btn:hover {
    color: var(--white);
}

.export-csv-btn img {
    margin-right: 1rem;
    width: 32px;
}

.pagination li {
    display: inline-block;
}

.pagination li a {
    display: inline-block;
    background: var(--dark-blue-500);
    padding: 0.6rem 1rem;
    margin: 0 3px;
    border-radius: 5px;
    color: var(--light-gray) !important;
}

.pagination li.active a,
.pagination li a:hover {
    background: var(--gray);
    color: var(--white) !important;
}

.pagination li.disabled a {
    background: var(--dark-blue-700);
    color: var(--light-gray) !important;
    opacity: 0.8;
    cursor: not-allowed;
}


/* Show Wines Screen Ends */


/* Location Screen */

.location {
    display: inline-block;
    background: var(--dark-blue-700);
    color: var(--light-gray);
    padding: 0.6rem 1rem;
    font-size: 1.5rem;
    border-radius: 5px;
    margin: 0 0 0.5rem 1rem;
}

.location p {
    margin-right: 0.8rem;
}

.location-btn {
    border-radius: 50%;
    margin-left: 0.5rem;
    font-size: 13px;
    width: 35px;
    height: 35px;
    padding: 0;
}

.location-btn.btn-primary {
    background: var(--theme-blue);
}


/* Location Screen Ends */


/* Landing Page CSS */

.navbar-dark {
    background: var(--dark-blue-800);
}

.main-navigation .navbar-brand img {
    max-height: 90px;
}

.main-navigation .nav-item {
    padding: 0.5rem 1.5rem;
    display: flex;
    align-items: center;
}

.main-navigation .nav-item a {
    color: var(--white);
    cursor: pointer;
}

.main-navigation .nav-item a:hover {
    text-decoration: none;
    color: var(--theme-blue);
}

.banner {
    margin-top: 200px;
}

.banner h1 {
    font-size: 3.3rem;
    font-weight: bold;
    color: var(--white);
}

.banner .btn {
    padding: 1rem 2.3rem;
    border-radius: 80px;
    text-transform: uppercase;
    /* background: rgb(232, 0, 116); */
    background: transparent;
    color: var(--white);
    border: 2px solid var(--dark-blue-500);
}

.banner .btn:hover {
    background: var(--dark-blue-500);
}

.banner .btn-pink {
    background: var(--theme-pink);
    border-color: var(--theme-pink);
    box-shadow: 0 0 0 8px rgba(232, 0, 116, 0.5);
}

.banner .btn-pink:hover {
    background: var(--theme-pink);
    box-shadow: 0 0 0 8px rgba(232, 0, 116, 0.3);
}

.features h2 {
    font-size: 2.8rem;
    color: var(--white);
}

.features svg {
    width: 3rem !important;
    height: 3rem;
}

.features h5 {
    color: var(--white);
    margin-top: 1.5rem;
}

.features p {
    font-size: 0.8rem;
    color: var(--light-gray);
}

.features-box {
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 20px;
    padding: 1.2rem;
    background: var(--dark-blue-500);
    /* Old browsers */
    background: -moz-linear-gradient( top, var(--dark-blue-500) 0%, var(--dark-blue-800) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( top, var(--dark-blue-500) 0%, var(--dark-blue-800) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( to bottom, var(--dark-blue-500) 0%, var(--dark-blue-800) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='var(--dark-blue-500)', endColorstr='var(--dark-blue-800)', GradientType=0);
    /* IE6-9 */
}

.dark-box {
    width: 100%;
    display: inline-block;
    border-radius: 20px;
    padding: 1.2rem;
    background: var(--dark-blue-900);
}

.big-text {
    font-size: 3rem;
    color: var(--theme-blue);
}

.contact h3 a {
    font-size: 1.2rem;
    font-weight: normal;
    text-decoration: none;
    color: var(--white);
}

.contact h3:hover>a {
    color: var(--theme-blue);
}

.contact h3 {
    margin-bottom: 2rem;
}

.copyright {
    background: var(--dark-blue-900);
}

.copyright p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: var(--light-gray);
}


/* Landing Page CSS Ends */


/* Select Plan Box */

.select-plan-box .modal-content {
    background: radial-gradient(ellipse at 75% 25%, #b0bbfc 0%, #4866ff 100%);
    color: var(--white);
    border-radius: 20px;
    border: none;
}

.select-plan-box .modal-content .form-control {
    padding: 0.8rem 0.5rem;
    color: var(--white);
    border-radius: 5px;
    border-color: white;
    height: auto;
    background: transparent;
}

.select-plan .form-check {
    padding: 0.5rem;
    z-index: 1;
}

.select-plan .form-check h3 {
    margin-bottom: 0;
}

.select-plan .form-check-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    z-index: -1;
    border-radius: 10px;
    border: none;
    transition: 0.2s;
    cursor: pointer;
}

.select-plan .form-check-label input:checked~.checkmark {
    background: var(--dark-blue-500);
    /* Old browsers */
    background: -moz-linear-gradient(bottom, #7d8ff8 0%, #4866ff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, #7d8ff8 0%, #4866ff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, #7d8ff8 0%, #4866ff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#7D8FF8', endColorstr='#4866FF', GradientType=0);
    /* IE6-9 */
}


/* Select Plan Box ends */

.sort-table th.ascending::after {
    content: "";
    /* display: inline-block; */
    margin-left: 4px;
    position: relative;
    top: 16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white;
}

.sort-table th.descending::after {
    content: "";
    /* display: inline-block; */
    margin-left: 4px;
    position: relative;
    bottom: 13px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
}

.sort-table th {
    cursor: pointer;
}


/* Responsive Media Queries */

@media only screen and (max-width: 1280px) {
    .dashboard-header {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media only screen and (max-width: 1199px) {
    .pro-sidebar {
        left: -100%;
    }
    .pro-sidebar.open {
        left: 0;
    }
}

@media only screen and (max-width: 1024px) {
    .banner {
        margin-top: 100px;
    }
}


/* Responsive Media Queries Ends */